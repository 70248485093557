export const getOrderDetailsQueryKey = (orderUuid: uuid) => {
  return ['order', 'details', orderUuid]
}

export const getBudgetCategoriesForOrderQueryKey = (
  orderId: number | undefined
) => {
  return ['budgetCategoriesForOrder', orderId]
}

export const getSpendBySecondaryCategoryQueryKey = (
  secondaryCategoryId: number | undefined,
  orderId: number | undefined,
  startDate: ISODateString,
  endDate: ISODateString
) => {
  return [
    'spendBySecondaryCategory',
    { secondaryCategoryId, orderId, startDate, endDate },
  ] as const
}
