import { type PaginationConfigs } from './pagination.types'

export const CONFIGS_BY_ID: PaginationConfigs = {
  'expense-summary': {
    defaultPagination: {
      orderBy: '-id',
    },
    mode: 'url',
  },
  'user-list': {
    defaultPagination: {
      orderBy: 'firstName',
    },
    mode: 'url',
  },
  role: {
    defaultPagination: {
      orderBy: '-name',
    },
    mode: 'local',
  },
  'audit-log': {
    mode: 'local',
  },
}
